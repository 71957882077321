import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

import { getOrderByStatus, getOrders } from '../../services/orders.api';
import { ProductInfo } from '../../services/models';
import { getProductPrice, getProductById } from '../../services/products.api';
import { usePageLoaderContext } from '../account';
import Loader from '../../components/common/Loader';
import useHasMounted from '../../hooks/usesHasMounted';
import UpcommingOrders from 'components/UpcommingOrders';
import Style from "./refer.module.scss"
import CustomSpinner from 'components/Spinner';

const OrderStatus = loadable(() =>
  import('components/Dashboard/SubscriptionDeliveries/OrderStatus'),
);
const OrderComponent = loadable(() =>
  import('components/Account/OrderComponent'),
);
const ManageDeliveries = loadable(() =>
  import('components/Dashboard/ManageDeliveries'),
);
const ButtonLoader = loadable(() => import('components/common/ButtonLoader'));

const initialState = {
  next: null,
  latestOrderId: 0,
  results: [],
};
const orderStatusFilter=['All',"Placed", "Packed", "Shipped", "Delivered", "Failed"]
export default () => {
  const [ordersState, setOrders] = useState(initialState);
  const [productPrice, setProductPrice] = useState(0);
  const [productData, setProductData] = useState({} as ProductInfo);
  const [manageDeliveriesVisible, showManageDeliveries] = useState(false);
  const [loading, setLoadingStatus] = useState(false);
  const [loadingItem, setLoadingItems] = useState(false);
  const { showPageLoader, setShowPageLoader } = usePageLoaderContext();
  const hasMounted = useHasMounted();
  const [sortCondition, onSortChanged] = useState("All");
  const loadResults = async (
    pageNumber = 1,
    status?: string | undefined,
    reset?: boolean | undefined,
  ) => {
    if (reset) setLoadingItems(true);
    const [orders, price, productData] = await Promise.all([
      status ? getOrderByStatus(status, pageNumber) : getOrders(pageNumber),
      getProductPrice(),
      getProductById(),
    ]);
    const latestOrder = orders.results[0];
    const ordersDispatched = orders.results;
    setOrders({
      next: orders.next,
      latestOrderId: latestOrder && latestOrder.number,
      results: [...(reset ? [] : ordersState.results), ...ordersDispatched],
    });
    setProductPrice(parseFloat(price.incl_tax));
    if (productData.data) {
      setProductData(productData.data);
    }
    if (reset) setLoadingItems(false);
  };

  const getURLParameter = (url: string, paramName: string) => {
    const regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const showMoreResults = async () => {
    setLoadingStatus(true);
    const nextPage=getURLParameter(ordersState.next,'page')
    const status=getURLParameter(ordersState.next,'status')
    await loadResults(nextPage,status);
    setLoadingStatus(false);
  };

  useEffect(() => {
    (async () => {
      setShowPageLoader(true);
      await loadResults(1,undefined,true);
      setShowPageLoader(false);
    })();
  }, []);

  const hasOrders = ordersState.results.length > 0;

  if (!hasMounted) {
    return null;
  }

  return (
    <>
      {manageDeliveriesVisible ? (
        <ManageDeliveries
          hideManageDeliveries={() => showManageDeliveries(false)}
          fetchData={() => {}}
          productPrice={productPrice}
        />
      ) : showPageLoader ? (
        <Loader position="relative" />
      ) : (
        <div className="page-wrapper">
          <div className="dashboard-wrapper">
            <div className="dash-banner">
              <div className="container-fluid">
                <div className="def-w-max">
                  <div className="row">
                    <div className="col-lg-12 dash-col-or">
                      <div className="hdr">
                        <h2>Orders</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`dash-contents ${Style.PaddingFix}`}>
              <div className="container-fluid">
                <div className="def-w-max">
                  <UpcommingOrders />
                </div>
              </div>
            </div>
            <div className="dash-contents">
              <div className="container-fluid">
                <div className="def-w-max">
                  {ordersState.latestOrderId ? (
                    <OrderStatus
                      id={ordersState.latestOrderId}
                      showManageDeliveries={showManageDeliveries}
                    />
                  ) : (
                    <></>
                  )}
                  <div
                    className={Style.FilterContainer}>
                    <select
                      style={{ width: '175px' }}
                      value={sortCondition}
                      onChange={e => {
                        setOrders({
                          next: null,
                          latestOrderId: 0,
                          results: [],
                        });
                        let status =
                          e.target.value === 'All' ? undefined : e.target.value;
                        onSortChanged(e.target.value);
                        loadResults(1, status,true);
                      }}
                    >
                      {orderStatusFilter.map((it,index) => (
                        <option key={"order-status-filter"+index} value={it}>{it}</option>
                      ))}
                    </select>
                  </div>
                  {hasOrders ? (
                    <div>
                        {ordersState.results.map((order, index) => {
                          return (
                            <OrderComponent
                              key={index}
                              order={order}
                              productData={productData}
                              collapse={index !== 0}
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <p>{<CustomSpinner isLoading={loadingItem}>We could not find any orders for you.</CustomSpinner>}</p>
                  )}

                  {ordersState.next ? (
                    <div className="su-btn bt-wrap1 text-center btn-wrap-hlt">
                      <ButtonLoader
                        className="btn-def"
                        onClick={showMoreResults}
                        text="Show more results"
                        loading={loading}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
